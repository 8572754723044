import * as cssHelper from '../../../js/document/css'
import JSONFetcher from '../../../js/helpers/json-fetcher'
import Backdrop from '../backdrop/main'
import Component from '../../../js/core/component/component'
import { registerComponent } from '../../../js/core/component/component-directory'
import registeredEvents from '../../../js/helpers/registered-events'

const selectorNames = {
  modalApi: 'c-modal-v2',
  videoApi: 'c-video'
}

const componentQueries = {
  modalBody: `[data-${selectorNames.modalApi}-body]`,
  modalFooter: `[data-${selectorNames.modalApi}-footer]`,
  modalHeader: `[data-${selectorNames.modalApi}-header]`
}

const definition = {
  name: 'c-modal-v2',

  /**
   * Props
   *
   * Most components can be customized with different parameters when they
   * are created. These creation parameters are called props.
   *
   * This lets you make a single component that is used in many different
   * places in your app, with slightly different properties in each place.
   *
   *
   */

  props: [
    {
      name: 'open',
      attr: '.in',
      type: 'boolean',
      defaultValue: false
    },
    {
      name: 'title',
      type: 'string'
    },
    {
      name: 'body',
      type: 'string'
    },
    {
      name: 'source',
      type: 'string',
      attr: 'data-source',
      defaultValue: null
    },
    {
      name: 'closable',
      type: 'boolean',
      attr: 'data-c-modal-v2--closable'
    },
    {
      name: 'openOnLoad',
      type: 'boolean',
      attr: 'data-c-modal-v2--open-on-load'
    },
    {
      name: 'hiddenBackdrop',
      type: 'boolean',
      attr: 'data-c-modal-v2--hidden-backdrop'
    }
  ],
  actionElements: true
}

const attr = {
  track: 'data-track'
}

/**
 * A modal window.
 */
export default class ModalV2 extends Component {
  /**
   * Creates a new modal behaviour, exposes an API to the element.
   *
   * @constructor
   * @param {Element} element - The HTML element.
   */
  constructor (element) {
    super(element, definition.name)

    this.settings = {
      classNames: {
        opened: 'in',
        opening: 'is-opening',
        closing: 'is-closing',
        loading: 'is-loading',
        loaded: 'is-loaded'
      }
    }

    this.modalBody = this.element.querySelector(componentQueries.modalBody)
    this.modalFooter = this.element.querySelector(componentQueries.modalFooter)
    this.modalHeader = this.element.querySelector(componentQueries.modalHeader)

    this.backdrop = null
    this.fetcher = new JSONFetcher()
    this._transitionTime = cssHelper.cssTimeToMs(
      cssHelper.getStyle(this.element, 'transition-duration')
    )
    this._addEscCloseEvent()

    element[this.name].remove = this.remove.bind(this)
    element[this.name].setOptions = this.setOptions.bind(this)
    element[this.name].open = this.open.bind(this)
    element[this.name].close = this.close.bind(this)
    element[this.name].updateModalHeight = this.updateModalHeight.bind(this)

    registeredEvents.registerComponentEvents(definition.name, this.events, {
      ...this.element.hasAttribute(attr.track) && { track: this.element.attributes[attr.track].value }
    })

    if (this.getProp('openOnLoad')) {
      this.open()
    }
  }

  getMaxBodyHeight () {
    const windowHeight = window.innerHeight
    const footerHeight = this.modalFooter ? this.modalFooter.offsetHeight : 0
    const headerHeight = this.modalHeader ? this.modalHeader.offsetHeight : 0
    // we set the maximum height possible in the body, so (thanks to maxHeight in the element)
    // so can calculate the padding needed (the difference between the element and the window height)
    // once the calculation is done, we set the height of the body to unset again
    this.modalBody.style.height = window.innerHeight + 'px'
    const paddingElement = window.innerHeight - this.element.offsetHeight
    this.modalBody.style.height = 'unset'

    const modalBodyMaxHeight =
      windowHeight - footerHeight - headerHeight - paddingElement + 1

    return modalBodyMaxHeight + 'px'
  }

  updateModalHeight () {
    this.modalBody.style.maxHeight = 'unset'
    this.modalBody.style.maxHeight = this.getMaxBodyHeight()
  }

  setOpen (value) {
    return value ? this._openAsync() : this._close()
  }

  getTitle () {
    const title = this._getTitleElement()
    return title ? title.innerText : ''
  }

  setTitle (value) {
    const title = this._getTitleElement()
    if (title) {
      title.innerText = value
    }
  }

  getBody () {
    return this._getBodyElement().innerHTML
  }

  setBody (value) {
    this._getBodyElement().innerHTML = value
  }

  setOptions (options) {
    Object.keys(options).forEach(([key, value]) => {
      this[key] = value
    })
  }

  open (elementOrSource = null) {
    const source =
      elementOrSource instanceof window.HTMLElement
        ? elementOrSource.getAttribute(`data-${this.name}__source`)
        : elementOrSource
    if (source) {
      this.setProp('source', source)
    }

    return this.setProp('open', true)
  }

  close () {
    return this.setProp('open', false)
  }

  _getTitleElement () {
    this.titleElement =
      this.titleElement ||
      this.element.querySelector(
        '.c-modal-v2__header .c-modal-v2__header-text'
      )
    return this.titleElement
  }

  _getBodyElement () {
    this.bodyElement =
      this.bodyElement || this.element.querySelector('.c-modal-v2__body')
    return this.bodyElement
  }

  /**
   * Opens the modal window.
   *
   * @returns {Promise} .
   */
  async _open () {
    return new Promise(resolve => {
      this.events.emit('open')
      this._openBackdrop(() => {
        this._setBackdropLoading(!!this.fetcher.data)

        this.events.emit('opening')

        if (!this.fetcher.data) {
          this._addOpenClass()
        }

        setTimeout(() => {
          const fn = () => {
            this._setBackdropLoading(false)
            this.events.emit('opened')
            resolve()
          }

          if (this.fetcher.data) {
            this.fetcher.data.then(fn)
          } else {
            fn()
          }
        }, this._transitionTime)
      })
    })
  }

  _addOpenClass () {
    this.element.classList.remove(this.settings.classNames.opening)
    cssHelper.forceRepaint(this.element)
    this.element.classList.add(this.settings.classNames.opened)
    this.events.emit('onScreen')
    this._addbBackdropEvent()
    this.updateModalHeight()
  }

  _openBackdrop (fn) {
    if (this.getProp('hiddenBackdrop')) {
      fn()
    } else {
      this.backdrop = Backdrop()
      this.backdrop.open(fn)
    }
  }

  /**
   * add event backdrop closes when backdrop is clicked
   *
   *
   */
  _addbBackdropEvent () {
    if (!this.getProp('hiddenBackdrop')) {
      this.backdrop.element.addEventListener('click', () => {
        this.events.emit('backdropClicked')
        if (this.getProp('closable')) this.close()
      })
    }
  }

  /**
   * add event to close the modal when clicking the esc key
   *
   *
   */
  _addEscCloseEvent () {
    document.addEventListener('keyup', event => {
      const isOpened = this.element.classList.contains(
        this.settings.classNames.opened
      )
      const isClosable = this.getProp('closable')
      if (event.key === 'Escape' && isClosable && isOpened) {
        this.close()
        this.events.emit('EscClicked')
      }
    })
  }

  /**
   * Opens the modal window and loads the content asynchronously.
   *
   * @param   {String} source - URL to fetch the content
   * @returns {Modal}  Self instance.
   */
  _openAsync () {
    this.fetcher.data = null
    const source = this.getProp('source')
    if (!source) return this._open()
    this.element.classList.add(this.settings.classNames.opening)

    this.fetcher.fetch(source).then(data => {
      this._addOpenClass()

      this.setProp('title', data.title)
      this.setProp('body', data.content)

      this.element.classList.add(this.settings.classNames.loaded)
      this.events.emit('loaded')
    })

    return this._open()
  }

  _setBackdropLoading (opc) {
    if (this.backdrop) {
      this.backdrop.element.classList[opc ? 'add' : 'remove'](
        this.settings.classNames.loading
      )
    }
  }

  /**
   * Closes the modal window.
   *
   * @returns {Promise}
   */
  async _close () {
    return new Promise(resolve => {
      this.events.emit('close')
      this.element.classList.remove(this.settings.classNames.opened)
      this.element.classList.add(this.settings.classNames.closing)
      this._closeBackdrop()

      this.videos = this.element.querySelectorAll('.' + selectorNames.videoApi)
      if (this.videos) {
        for (const i in this.videos) {
          const videoElement = this.videos[i]
          const video = videoElement[selectorNames.videoApi]
          if (video) video.pauseVideo()
        }
      }

      setTimeout(() => {
        this.element.classList.remove(this.settings.classNames.closing)
        this.element.classList.remove(this.settings.classNames.loaded)
        this.events.emit('closed')
        resolve()
      }, this._transitionTime)
    })
  }

  _closeBackdrop () {
    if (this.backdrop) {
      this.backdrop.element.removeEventListener('click', () => this.close())
      this.backdrop.close(this.backdrop.destroy)
    }
  }

  remove () {
    this.close().then(() => {
      delete this.element[this.name]
    })
  }

  /**
   * Class methods to be used to load async a modal
   * @param   {Event} event    - URL to The event that sends the request to load async
   * @param   {String} modalId - The id of the modal to be loaded
   */
  static loadContentAsync (event, modalId) {
    const clickedElement = event.target

    if (clickedElement) {
      const buttonElement = clickedElement.closest(
        `[data-${definition.name}__source]`
      )

      if (buttonElement) {
        const modalElement = document.querySelector(`#${modalId}`)
        modalElement[definition.name].open(buttonElement)
      }
    }
  }
}

registerComponent(ModalV2, definition.name, definition)
